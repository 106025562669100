.sidebar-theme-light {
    .ps-menu-button:hover span i {
        color: #fff !important;
    }

    .ps-menu-button:hover .ps-submenu-expand-icon {
        color: var(--secondary-color)#fff !important;
    }
}

.sidebar-theme-dark {
    background-color: var(--bg-menu) !important;

    .mt-auto i {
        color: #fff !important;
    }
}

.layout-topbar-logo {
    height: 64px!important;
    .logo {
        scale: 0.8;
        height: 5rem;
        object-fit: contain;
    }
}