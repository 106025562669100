.layout-main-container {
    margin-top: 5rem;
    border-top: 1px solid var(--surface-border);
    height: calc(100vh - 5rem);
    padding: 2rem;
    box-shadow: inset 0 3px 4px rgba(0, 0, 0, .1);
}

.layout-main {
    flex: 1 1 auto;
    max-width: 1400px!important;
    margin: 0 auto;
}

.layout-main-pad {
    padding-top: 0rem;
}