/* Utils */
.clearfix:after {
    content: " ";
    display: block;
    clear: both;
}

.card {
    background: var(--surface-card);
    border: 1px solid var(--surface-border);
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: var(--card-shadow);
    border-radius: $borderRadius;
    // box-shadow: 10px 10px 20px #bebebe, -10px -10px 20px #ffffff;
    &:last-child {
        margin-bottom: 0;
    }
}

.p-toast {
    &.p-toast-top-right,
    &.p-toast-top-left,
    &.p-toast-top-center {
        top: 100px;
    }
}