.layout-config-button {
    display: block;
    position: fixed;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    background: var(--primary-color);
    color: var(--primary-color-text);
    text-align: center;
    top: 50%;
    right: 0;
    margin-top: -1.5rem;
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: background-color var(--transition-duration);
    overflow: hidden;
    cursor: pointer;
    z-index: 999;
    box-shadow: -.25rem 0 1rem rgba(0,0,0,.15);


    &.config-link {
        font-size: 1rem;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        border: none;
      }
      .config-link:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 1px var(--focus-ring);
      }

    i {
        font-size: 2rem;
        line-height: inherit;
        transform: rotate(0deg);
        transition: transform 1s;
    }

    &:hover {
        background: var(--primary-400);
    }
}

.layout-config-sidebar {
    &.p-sidebar {
        .p-sidebar-content {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
} 