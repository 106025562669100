/* General */
$borderRadius:4px;             /* border radius of layout element e.g. card, sidebar */
$transitionDuration:.2s;        /* transition duration of layout elements e.g. sidebar, overlay menus */
 
 
:root{
  --bg-menu: #5aa445!important;
  --text-color-menu: #ffffff!important;
  --menu-surface-hover: #324268!important;
  --color-primary: #5aa445!important;
  --color-black:#000!important;
  --primary-color:#5aa445!important;
  --secondary-color:#1d5368!important;
  --primary-color-text:#030712!important;
  --focus-ring: 0 0 0 0.2rem transparent!important;
  --bg-primary: #3b82f6!important;
  --link-color: #3b82f6!important;
}